<template>
    <div class="h-100 d-flex align-items-center">
        <b-form-input class="w-100 h-100" type="number" ref="input" v-model.number="value" min="0" step="0.01" @keydown="(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()"/>
    </div>
</template>
<script>
    import {countDecimals} from '@/libs/helpers'

    const {nextTick} = require('@vue/composition-api')
    import { BFormInput } from 'bootstrap-vue'
    import {
        getMoneyTypes,
        getWeightTypes,
        getVolumeTypes,
        getPieceTypes
    } from '@/libs/constants/ingredients.js'
    import {PackagingUnit} from '@/libs/enums/PackagingUnit'

    export default {
        components: {BFormInput},
        data() {
            return {
                value: null,
                unit: '',
                startValue: null
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            }
        },
        methods: {
            convertPackagingToUnit(packaging, unit) {
                let type = null
                if (unit === PackagingUnit.Milliliter || unit === PackagingUnit.Decilitre || unit === PackagingUnit.Liter) {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === PackagingUnit.Gram || unit === PackagingUnit.Decagram || unit === PackagingUnit.Kilogram) {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === PackagingUnit.Euro) {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return 0
                }

                const allowedDecimals = Math.log10(type.factor)
                const stock = Number(Number(packaging) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return stock
            },
            convertPackagingToDefault(packaging, unit) {
                let type = null
                if (unit === PackagingUnit.Milliliter || unit === PackagingUnit.Decilitre || unit === PackagingUnit.Liter) {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === PackagingUnit.Gram || unit === PackagingUnit.Decagram || unit === PackagingUnit.Kilogram) {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === PackagingUnit.Euro) {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return 0
                }

                const decimals = countDecimals(this.value)
                const allowedDecimals = Math.log10(type.factor)
                if (allowedDecimals < decimals) {
                    this.$printWarning(`${this.$t('print.warning.chosen_ingredient_1')} ${  allowedDecimals} ${this.$t('print.warning.chosen_ingredient_2')}`)
                    return
                }

                const stock = Number(Number(this.value) * Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return stock
            },
            getValue() {
                if (this.value === null || this.value === undefined || this.value === '') {
                    return this.startValue
                }
                return this.value
            }
        },
        mounted() {
            this.unit  = this.params.data.packaging_unit
            this.value = null
            this.startValue = this.params.data.stock

            nextTick(() => this.$refs.input.focus())
        }
    }

</script>
<template>
    <div style="height: 100%;">
        <ag-grid-vue class="overflow-visible"
                     ref="gridComponent"
                     style="width: 100%; height: 100%;"
                     :class="[themeSkin === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine']"
                     :id="'agGrid_' + getTableName()"
                     :columnDefs="columnDefs"
                     @grid-ready="onGridReady"
                     :defaultColDef="defaultColDef"
                     :rowSelection="rowSelection"
                     :gridOptions="gridOptions"
                     :rowData="rowData"
                     @cell-value-changed="onCellValueChanged"
                     :frameworkComponents="frameworkComponents"
                     :context="context"
                     @model-updated="resizeColumnsToFit"
                     :getRowClass="(params) => getRowClass(params)"
        >
        </ag-grid-vue>

        <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                    <span v-if="rowData.length > 0 && pagination">
                        {{ ((pagination.currentPage -1) * pagination.perPage) + 1 }} -
                        <span v-if="pagination.currentPage * pagination.perPage < pagination.totalRows">
                            {{ pagination.currentPage * pagination.perPage}}
                        </span>
                        <span v-else>
                            {{pagination.totalRows}}
                        </span>
                        {{$t('pagination.of')}} {{ pagination.totalRows }}
                    </span>
                </span>

                <b-dropdown variant="none" no-caret>
                    <template v-slot:button-content>
                        <span>({{ pagination.perPage }} {{$t('pagination.per_page')}}) </span>
                        <feather-icon icon="ChevronDownIcon" size="16" class="text-body align-middle mr-25" />
                    </template>

                    <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                        {{ length }}
                    </b-dropdown-item>

                </b-dropdown>
            </div>
            <div>
                <b-pagination :value="1" :total-rows="pagination.totalRows" :per-page="pagination.perPage" first-number
                              last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                              v-model="pagination.currentPage" @click.native="setPage(pagination.currentPage)">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {BDropdown, BDropdownItem, BPagination} from 'bootstrap-vue'
    import 'ag-grid-community/dist/styles/ag-grid.css'
    import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
    import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
    import {AgGridVue} from 'ag-grid-vue'
    import TextEditor from '@/views/components/Table/TextEditor.vue'
    import FloatingFilter from '@/views/components/Table/FloatingFilter.vue'
    import ColorEditor from '@/views/components/Table/ColorEditor.vue'
    import PriceAndPercentageEditor from '@/views/components/Table/PriceAndPercentageEditor.vue'
    import PackagingEditor from '@/views/components/Table/PackagingEditor.vue'
    import EmailEditor from '@/views/components/Table/EmailEditor.vue'
    import SelectEditor from '@/views/components/Table/SelectEditor.vue'
    import DateEditor from '@/views/components/Table/DateEditor.vue'
    import TableActions from '@/views/components/Table/TableActions.vue'
    import DisplayColor from '@/views/components/Table/DisplayColor.vue'
    import DisplayActive from '@/views/components/Table/DisplayActive.vue'
    import DisplayEditing from '@/views/components/Table/DisplayEditing.vue'
    import ActiveEditor from '@/views/components/Table/ActiveEditor.vue'
    import TextAreaEditor from '@/views/components/Table/TextAreaEditor.vue'
    import DisplayTemporaryEditing from '@/views/components/Table/DisplayTemporaryEditing.vue'
    import PasswordEdit from '@/views/components/Table/PasswordEdit.vue'
    import CustomTooltip from '@/views/components/Table/CustomTooltip.vue'
    import DisplayStatus from '@/views/components/Table/DisplayStatus.vue'
    import DisplayDifference from '@/views/components/Table/DisplayDifference.vue'
    import colorSelectorCell from '@/views/components/Table/ColorSelectorCell.vue'
    import NumberEditor from '@/views/components/Table/NumberEditor.vue'
    import PositiveNumberEditor from '@/views/components/Table/PositiveNumberEditor.vue'
    import DisplayAvailabilityStatus from '@/views/components/Table/DisplayAvailabilityStatus.vue'
    import DisplayPrice from '@/views/components/Table/DisplayPrice.vue'
    import DisplayPercent from '@/views/components/Table/DisplayPercent'
    import DisplayDate from '@/views/components/Table/DisplayDate'
    import DisplayTimeStamp from '@/views/components/Table/DisplayTimeStamp'
    import DisplayBillHistoryDate from '@/views/components/Table/DisplayBillHistoryDate.vue'
    import DisplayNumber from '@/views/components/Table/DisplayNumber.vue'
    import DisplayDeviceType from '@/views/components/Table/DisplayDeviceType.vue'
    import DisplayIssuedBillHistoryType from '@/views/components/Table/DisplayIssuedBillHistoryType.vue'
    import DisplayLanguage from '@/views/components/Table/DisplayLanguage.vue'
    import DisplayEmployeeTime from '@/views/components/Table/DisplayEmployeeTime.vue'
    import {HistoryType} from '@/libs/enums/IssuedBillHistoryTypes'

    export default {
        /*eslint-disable-next-line*/
        components: {DisplayLanguage,BPagination, BDropdown, BDropdownItem, AgGridVue, DisplayDeviceType, CustomTooltip, DisplayPrice, agTextColumnFloatingFilter: FloatingFilter, agCellEditor: TextEditor, colorEditor: ColorEditor, priceAndPercentageEditor: PriceAndPercentageEditor, packagingEditor: PackagingEditor, emailEditor: EmailEditor, activeEditor: ActiveEditor, passwordEdit: PasswordEdit, selectEditor: SelectEditor, textAreaEditor: TextAreaEditor, agDateInput: DateEditor, btnCellRenderer:TableActions,  DisplayEditing, DisplayColor, DisplayActive, DisplayStatus, DisplayDifference, colorSelectorCell, numberEditor:NumberEditor, DisplayPercent, DisplayDate, DisplayTimeStamp, DisplayBillHistoryDate, DisplayTemporaryEditing, DisplayAvailabilityStatus, DisplayNumber, PositiveNumberEditor, DisplayIssuedBillHistoryType, DisplayEmployeeTime},
        props: {
            name: {
                type: String
            },
            columnDefs: {
                type: Array
            },
            rowData: {
                type: Array
            },
            showRedForDisabled: {
                default: true
            }
        },
        data() {
            return {
                frameworkComponents: null,
                pagination: {
                    totalRows: 100,
                    perPage: 10,
                    currentPage: 1
                },
                lengths: ['10', '20', '50', '100'],
                gridOptions: {
                    paginationPageSize: 10,
                    pagination: true,
                    suppressPaginationPanel: true,
                    suppressScrollOnNewData: true,
                    overlayNoRowsTemplate:
                        `<span class="p-1" style="border: 1px solid black; background: #e6e6e6; color: black;">${this.$t('general.no_rows')}</span>`
                },
                gridApi: null,
                context: null,
                columnApi: null,
                defaultColDef: {
                    editable: true,
                    sortable: true,
                    resizable: true,
                    filter: false,
                    autoHeight: false,
                    flex: 1,
                    floatingFilter: true,
                    cellClass: 'cell-wrap-text',
                    minWidth: 110,
                    suppressChangeDetection: false

                },
                rowSelection: null,
                tableFilters: {
                    'page': 1,
                    'per_page': 10,
                    'filters': {
                    }
                }
            }
        },
        methods: {
            onGridReady(params) {
                params.api.sizeColumnsToFit()
                this.gridApi = params.api
                this.gridApi.addEventListener('filterChanged', this.onFilterChangedEvent)
                this.readTableFiltersFromURL()
            },
            onFilterChangedEvent() {
                this.tableFilters.filters = this.gridApi.getFilterModel()
                this.setTableFiltersToURL()
            },
            refresh() {
                this.gridApi.refreshCells({
                    force: true,
                    suppressFlash: true
                })
            },
            refreshHeaders() {
                this.gridApi.refreshHeader()
            },
            resizeColumnsToFit() {
                if (!this.gridApi) return
                this.gridApi.sizeColumnsToFit()
            },
            setPageLength(length) {
                this.pagination.perPage = length
                this.gridApi.paginationSetPageSize(Number(length))
                this.tableFilters.per_page = length
                this.setTableFiltersToURL()
            },
            setPage(num) {
                this.tableFilters.page = num
                this.pagination.currentPage = num
                this.setTableFiltersToURL()
            },
            onCellValueChanged(params) {
                this.$emit('edit', params)
            },
            viewTables(id) {
                this.$emit('viewTables', id)
            },
            refundIssuedInvoice(params) {
                this.$emit('refundIssuedInvoice', params)
            },
            deleteRow(params) {
                this.$emit('deleteRow', params)
            },
            viewPrintout(params, index) {
                this.$emit('viewPrintout', params, index)
            },
            editRow(params, index) {
                this.$emit('editRow', params, index)
            },
            itemAdded(id) {
                this.$emit('itemAdded', id)
            },
            confirmBusinessUnitOnFurs(id, isAlreadyConfirmed) {
                this.$emit('confirmBusinessUnitOnFurs', id, isAlreadyConfirmed)
            },
            changeOrder(id) {
                this.$emit('changeOrder', id)
            },
            viewDetails(params) {
                this.$emit('viewDetails', params)
            },
            viewTimeDetails(params) {
                this.$emit('viewTimeDetails', params)
            },
            viewTemporaryFile(params) {
                this.$emit('viewTemporaryFile', params)
            },
            clone(params, index) {
                this.$emit('clone', params, index)
            },
            generateEAN(params, index) {
                this.$emit('generateEAN', params, index)
            },
            enable(params, index) {
                this.$emit('enable', params, index)
            },
            disable(params, index) {
                this.$emit('disable', params, index)
            },
            getRowClass(params) {
                if (this.showRedForDisabled) {
                    if (params.data.disabled) {
                        return 'disabled-bg'
                    }
                }

                if (params.data.type === HistoryType.CurrentBill) {
                    return 'current-bill-bg'
                }

                return ''
            },
            getTableName() {
                if (this.name) return `t.${this.name}`
                return 't'
            },
            setTableFiltersToURL() {
                const payload = Buffer.from(JSON.stringify(this.tableFilters)).toString('base64')
                let payloadOld = ''
                if (this.$route.query && this.$route.query[this.getTableName()]) {
                    payloadOld = this.$route.query[this.getTableName()]
                }

                const type = (typeof (payloadOld))
                if (type === 'string' && payload !== payloadOld) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, [this.getTableName()]: payload } })
                } else if (type === 'object' && payload !== payloadOld[0]) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, [this.getTableName()]: payload } })
                }
            },
            readTableFiltersFromURL() {
                if (!this.$route.query || !this.$route.query[this.getTableName()]) {
                    this.tableFilters = {
                        'page': 1,
                        'per_page': 10,
                        'filters': {}
                    }
                    this.setConfig()
                    return
                }
                // get b64 date from query parameter and parse it
                const payload = this.$route.query[this.getTableName()]
                this.tableFilters = JSON.parse(atob(Buffer.from(payload).toString()))
                this.setConfig()
            },
            async setConfig() {
                this.gridApi.setFilterModel(this.tableFilters.filters)
                this.pagination.perPage = this.tableFilters.per_page
                this.gridApi.paginationSetPageSize(Number(this.tableFilters.per_page))
                this.pagination.currentPage = this.tableFilters.page
                this.gridApi.onFilterChanged()
            },
            changePaginationPageSize() {
                this.gridOptions.paginationPageSize = this.tableFilters.per_page
                this.gridApi.refreshCells()
            },
            detectClick(evnt) {
                if (!evnt.gridApi) {
                    return
                }
                const searchString = `#agGrid_${  this.getTableName() }`
                const item = evnt.target.closest(searchString)

                if (!item) {
                    this.gridApi.stopEditing()
                }
            }
        },
        beforeMount() {
            this.frameworkComponents = { agDateInput: 'agDateInput', agTextCellEditor: 'agTextCellEditor', btnCellRenderer: 'btnCellRenderer' }
            this.rowSelection = 'multiple'
            this.context = this

            this.pagination.totalRows = this.rowData.length
            window.addEventListener('resize', this.resizeColumnsToFit)
        },
        destroyed() {
            window.removeEventListener('resize', this.resizeColumnsToFit)
        },
        computed: {
            themeSkin() {
                return this.$store.getters['appConfig/currentSkin']
            }
        },
        mounted() {
            window.addEventListener('click', this.detectClick)
        },
        beforeDestroy() {
            window.removeEventListener('click', this.detectClick)
        },
        watch: {
            'columnDefs'() {
                this.gridApi.refreshInMemoryRowModel()
                this.gridApi.refreshHeader()
                this.gridApi.refreshView()
            },
            'pagination.currentPage'(val) {
                this.gridApi.paginationGoToPage(val - 1)
            },
            'rowData'(val) {
                this.pagination.totalRows = val.length

                this.$nextTick(() => {
                    this.readTableFiltersFromURL()
                })
            },
            'gridApi.paginationProxy.masterRowCount'() {
                this.pagination = {
                    totalRows: this.gridApi.paginationGetRowCount(),
                    perPage: this.gridApi.paginationGetPageSize(),
                    currentPage: this.gridApi.paginationGetCurrentPage() + 1
                }
            }
        }
    }
</script>

<style>

/*.current-bill-bg {
  background: rgba(197, 199, 197, 0.4) !important;
  color: #181d1f !important;
}*/

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    height: 100%;
}

.ag-floating-filter-button,
.ag-header-icon.ag-header-cell-menu-button {
    display: none;
}

.ag-root-wrapper {
    /*border-radius: 0.428rem;*/
}

.ag-text-field-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    display: block;
    width: 100%;
    height: 100% !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cell-wrap-text {
    /*white-space: normal;*/
}

.ag-theme-alpine-dark .ag-root-wrapper {
    border: none !important;
}

.ag-theme-alpine-dark .ag-header {
 background: #4b4b4b;
}
.ag-theme-alpine-dark .ag-header-cell.ag-floating-filter {
  border: none !important;
}
.ag-theme-alpine-dark .ag-row {
 background-color: #454545;
}
.ag-theme-alpine-dark .ag-row:nth-child(even) {
 background-color: #343434;
}

.ag-theme-alpine-dark .ag-header-cell.ag-floating-filter {
    border: none !important;
}

.ag-theme-alpine-dark .ag-row {
    background-color: #535353;
}

.ag-theme-alpine-dark .ag-row:nth-child(even) {
    background-color: #343434;
}

.ag-theme-alpine-dark .ag-checkbox-input-wrapper {
    border: none;
}

.ag-header, .ag-header-viewport, .ag-floating-filter, .ag-header-row-column-filter, .ag-root-wrapper, .ag-root {
  overflow: visible!important;
  z-index: 4!important;
}

.ag-root-wrapper-body {
    overflow-x: clip!important;
}

.ag-theme-alpine-dark .ag-checkbox-input-wrapper::after {
    background: #575757;
    border: none;
}</style>
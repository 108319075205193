<template>
    <div>
        <b-badge v-if="item" :variant="item.variant" :style="[item.value === HistoryType.ReversalBill ? 'background-color: #BD4546 !important;' : '']">{{item.name}}</b-badge>
    </div>
</template>

<script>
    import { BBadge } from 'bootstrap-vue'
    import {HistoryType} from '@/libs/enums/IssuedBillHistoryTypes'
    import {BillChangeType} from '@/libs/enums/BillChangeType'

    export default {
        computed: {
            HistoryType() {
                return HistoryType
            }
        },
        components: {BBadge},
        data() {
            return {
                value: null,
                item: null,
                aa: 'rgb(189,69,70)',
                array: [
                    {name: this.$t('reporting.issued_bills.history.original_bill'), value: HistoryType.OriginalBill, variant: 'info'},
                    {name: this.$t('reporting.issued_bills.history.reversal_bill'), value: HistoryType.ReversalBill, variant: 'danger'},
                    {name: this.$t('reporting.issued_bills.history.issued_invoice'), value: HistoryType.IssuedInvoice, variant: 'dark'},
                    {name: this.$t('reporting.issued_bills.history.refund_invoice'), value: HistoryType.RefundInvoice, variant: 'danger'},
                    {name: this.$t('reporting.issued_bills.history.buyer_change'), value: BillChangeType.BuyerChange, variant: 'warning'},
                    {name: this.$t('reporting.issued_bills.history.current_bill'), value: HistoryType.CurrentBill, variant: 'primary'}
                ]
            }
        },
        methods: {
            refresh(params) {
                this.value = this.getValueToDisplay(params)
                this.setItem()
            },
            getValueToDisplay(params) {
                return params.data
            },
            setItem() {
                let item = this.array.find(x => x.value === this.value.type)

                if (!item && this.value.type === HistoryType.BillChange) {
                    if (this.value.bill_version === 0) {
                        item = this.array.find(x => x.value === HistoryType.OriginalBill)
                    } else if (this.value.bill_change_type === BillChangeType.BuyerChange) {
                        item = this.array.find(x => x.value === BillChangeType.BuyerChange)
                    }
                }

                if (item) {
                    this.item = item
                }
            }
        },
        beforeMount() {
            this.value = this.getValueToDisplay(this.params)
            this.setItem()
        }
    }
</script>

<style scoped>
</style>